import * as React from 'react';
import { useEffect, useState } from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import './styles/thankyou.scss';
import { withTranslation } from 'react-i18next';
import { getLanguageDefault, navigateUrl, URL, FormatList, BackgroundList } from '../utils/Utils';
import ThankyouParameters from '../common/models/thankyou';
import ThankyouComponent from '../components/thankyou-component/thankyou-component';
import OneLineComponent from '../common/oneLineComponent/oneLineComponent';

const ThankYouPage = props => {
  const { location, t, i18n } = props;
  const parameters = new ThankyouParameters(location.search);

  const status = parameters.getStatus();
  const sessionID: any = parameters.getSessionID();
  const payment_id = parameters.getPaymentID();

  var timeoutLanguage: any = null;
  const language = getLanguageDefault().code.toLowerCase();

  const [format, setFormat] = useState(FormatList[0]);
  const [background, setBackground] = useState(BackgroundList[0]);

  const onFormatChange = e => {
    setFormat(e.value);
  };

  const onBackgroundChange = e => {
    setBackground(e.value);
  };

  const navigateToDonate = () => {
    navigateUrl(`${sessionID?.urlDefault}`);
  };

  useEffect(() => {
    if (!sessionID) {
      navigateUrl(`/${URL.NOT_FOUND}`);
      return;
    }
    if (status === 'failed') {
      navigateUrl(`/${URL.DONATE_FAILURE}`);
      return;
    }

    timeoutLanguage = setTimeout(() => {
      i18n.changeLanguage(language);
    }, 50);

    return () => {
      clearTimeout(timeoutLanguage);
    };
  }, []);

  return (
    <Layout isShowAction={false} minHeight="100vh" style={{ background: '#F7F7F7' }}>
      <Seo title="Thank you" />
      {t('dearText') !== 'dearText' ? (
        <div>
          <OneLineComponent navigateToDefault={navigateToDonate} />
          <ThankyouComponent
            language={language}
            sessionData={sessionID}
            format={format}
            onFormatChange={onFormatChange}
            background={background}
            onBackgroundChange={onBackgroundChange}
            location={location}
            parameter={parameters}
            setBackground={setBackground}
            payment_id={payment_id}
          />
        </div>
      ) : (
        <div />
      )}
    </Layout>
  );
};

export default withTranslation('blog')(ThankYouPage);
