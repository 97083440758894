import React, { useState, useRef, useEffect } from 'react';
import './thankyou-component.scss';
import { Dropdown } from 'primereact/dropdown';
import { withTranslation } from 'react-i18next';
import {
  FormatList,
  BackgroundList,
  TITLE_OPTION_FORM,
  VIDEO_LINK,
  LocaleLanguage,
  IMAGE_BACKGROUND,
  THANKYOU_LINK,
  IS_MOBILE_PLATFORM,
} from '../../utils/Utils';
import TabsFormDonation from '../../components/donationForm/components/tabFormDonation/tabFormDonation';
import { Button } from 'primereact/button';
import html2canvas from 'html2canvas';
import DonationFormAPI from '../../services/donationFormAPI';
import { Toast } from 'primereact/toast';
import ReactHtmlParser from 'react-html-parser';
import { convertRhythm } from '../../utils/funtionCommon';
import logo_white from '../../images/logo-white.png';
import LoadingComponent from '../../common/Loading';
import { env } from '../../environment';

const ThankyouComponent = props => {
  const {
    t,
    language,
    sessionData,
    format,
    onFormatChange,
    background,
    onBackgroundChange,
    setBackground,
    payment_id,
  } = props;

  const tabsOptions = [
    { title: TITLE_OPTION_FORM[language?.toUpperCase() || 'DE'].STEP_INFO },
    { title: TITLE_OPTION_FORM[language?.toUpperCase() || 'DE'].STEP_DIS },
    { title: TITLE_OPTION_FORM[language?.toUpperCase() || 'DE'].STEP_THANKS },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [isDisableDownload, setisDisableDownload] = useState(true);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [thankyouInfo, setThankyouInfo] = useState<any>({
    videoUrl: '',
    additionalMessage: '',
    data: null,
  });
  const callCount = useRef(0);
  const toast = useRef(null);
  let intervalTimeout: any = null;
  let intervalFetchData: any = null;
  const [loading, setLoading] = useState(false);
  const [heightSurvey, setHeightSurvey] = useState(0);

  const dowloadImage = () => {
    setIsLoading(true);
    const element: any = document.getElementById('image-with-text');

    try {
      html2canvas(element, {
        imageTimeout: 15000,
        allowTaint: true,
        useCORS: true,
        backgroundColor: null,
        scale: 2,
      }).then(canvas => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL();
        link.download = background ? background.name : 'image';
        link.click();
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const fetchAndSendEvent = async data => {
    if (window && window['dataLayer']) {
      window['dataLayer'].push({
        event: 'purchase',
        ecommerce: {
          transaction_id: payment_id,
          value: data?.donation_money,
          currency: data?.payment_currency_code,
          rhythm: convertRhythm(data?.rhythm),
          items: [
            {
              item_id: data?.campaign ? data?.campaign?.uuid : data?.region?.uuid,
              item_name: data?.campaign ? data?.campaign?.code : data?.region?.code,
            },
          ],
        },
      });
    }
  };

  const setImageBackgroundDefault = (data: any) => {
    const identifier = data?.campaign ? data?.campaign?.code : data?.region?.code;
    if (identifier) {
      const isCanada = IMAGE_BACKGROUND.find(el => el.name === identifier);
      if (isCanada && isCanada?.code === 'Nebel') {
        setBackground(BackgroundList[1]);
      } else {
        setBackground(BackgroundList[2]);
      }
    } else {
      setBackground(BackgroundList[2]);
    }
  };

  useEffect(() => {
    setLoading(true);
    onGetVideo();
    intervalFetchData = setInterval(() => {
      callCount.current += 1;
      onGetVideo();
      if (callCount.current === 2) {
        clearInterval(intervalFetchData);
      }
    }, 3000);
    return () => {
      clearInterval(intervalFetchData);
    };
  }, []);

  const generateFileDownload = (filename: string, data: any) => {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const url: any = URL.createObjectURL(new Blob([byteArray], { type: 'application/pdf; charset=utf-8' }));
    generateLinkDownLoad(filename, url);
  };

  const generateLinkDownLoad = (filename: string, url: any) => {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const showNotification = (type, message) => {
    // @ts-ignore: Object is possibly 'null'.
    toast.current.show({ severity: `${type}`, summary: `${t('notification')}`, detail: `${message}`, life: 5000 });
  };

  const onDownloadCertificate = async () => {
    setIsLoadingDownload(true);
    try {
      const res = await DonationFormAPI.getCertificateDonation(sessionData?.sessionId);
      if (res && res.status === 200) {
        generateFileDownload(res.data.name, res.data.content);
        showNotification('success', 'Dowload certificate successfully!');
      } else {
        showNotification('error', 'Dowload certificate failed!');
      }
      setIsLoadingDownload(false);
    } catch (error) {
      if (error?.response?.data?.error === 'Not Found') {
        showNotification(
          'error',
          language === 'de'
            ? 'Die individuellen Koordinaten deiner Spende werden zugeordnet. Bitte versuche es später.'
            : 'The individual coordinates of your donation are being assigned. Please try again later.',
        );
      } else {
        showNotification('error', 'Dowload certificate failed!');
      }
      setIsLoadingDownload(false);
    }
  };

  const combineMediaUrl = (mediaUrl: any) => {
    if (mediaUrl && mediaUrl.startsWith('https://')) {
      return mediaUrl;
    }

    return `${env.IMAGE_URL}/${mediaUrl}`;
  };

  const onGetVideo = async (isActionLoading = false) => {
    try {
      let res;
      res = await DonationFormAPI.getDataBySession(sessionData?.sessionId);
      if (res && res.status === 200 && res.data) {
        let videoURL: string = '';
        let additionalMessageValue: string = '';
        let pathData = res.data?.campaign ? res.data.campaign : res.data.region;

        if (pathData.video_type === 'yt') {
          var videoId = getVideoIdFromUrl(
            pathData?.video_url?.[language?.toLowerCase() || 'de'] || VIDEO_LINK[language?.toUpperCase() || 'DE'],
          );
          videoURL = 'https://www.youtube.com/embed/' + videoId;
        } else if (pathData.video_type === 'vm') {
          var videoId = getVideoIdFromUrlVimeo(
            pathData?.video_url?.[language?.toLowerCase() || 'de'] || VIDEO_LINK[language?.toUpperCase() || 'DE'],
          );
          videoURL = 'https://player.vimeo.com/video/' + videoId;
        } else {
          videoURL =
            pathData?.video_url && pathData?.video_url?.de
              ? combineMediaUrl(pathData.video_url[language?.toLowerCase() || 'de'])
              : VIDEO_LINK[language?.toUpperCase() || 'DE'];
        }

        additionalMessageValue = (pathData.message ? pathData.message[language?.toLowerCase() || 'de'] : '')
          .replace(/^<p>/, '')
          .replace(/<\/p>$/, '');

        setThankyouInfo({
          videoUrl: videoURL,
          additionalMessage: additionalMessageValue,
          data: res.data,
        });

        fetchAndSendEvent(res.data);
        setImageBackgroundDefault(res.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getVideoIdFromUrl = (url: string) => {
    if (!url || url === '') {
      return '';
    }
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return (match && match[1]) || '';
  };

  const getVideoIdFromUrlVimeo = (url: string) => {
    if (!url || url === '') {
      return '';
    }
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/)([0-9]+)/;
    const match = url.match(regex);
    return match ? match[1] : '';
  };

  useEffect(() => {
    if (thankyouInfo && thankyouInfo.data) {
      let timeDis = thankyouInfo.data.area / 100;
      const heightIframe = IS_MOBILE_PLATFORM() ? thankyouInfo?.data?.settings?.survey_settings?.jotFormIdMobileHeight : thankyouInfo?.data?.settings?.survey_settings?.jotFormIdHeight;
      setHeightSurvey(heightIframe);

      switch (timeDis >= 0) {
        case timeDis <= 5:
          timeDis = 5000;
          break;
        case timeDis <= 10:
          timeDis = 10000;
          break;
        case timeDis <= 50:
          timeDis = 30000;
          break;
        case timeDis <= 100:
          timeDis = 60000;
          break;
        default:
          timeDis = 60000;
      }

      intervalTimeout = setTimeout(() => {
        setisDisableDownload(false);
      }, timeDis);
    }

    return () => {
      clearInterval(intervalTimeout);
    };
  }, [thankyouInfo]);

  return (
    <div className="thank-you-page">
      {loading && <LoadingComponent />}
      <div className="page-thankyou-container">
        <div className="row container-page">
          <div className="col-md-7 content-left">
            <div className="thank-content-left">
              <TabsFormDonation tabsOptions={tabsOptions} page={2} disabledAnimation={true} />
              <div className="page-thankyou-wrapper">
                <div className="thankyou-wrapper">
                  <h1>{t('done')}</h1>
                  <h1 className="w-dark-yellow-text">
                    {t('titleThanks')} {thankyouInfo?.data?.first_name}!
                  </h1>
                  <h2
                    className="description-label"
                    dangerouslySetInnerHTML={{
                      __html: thankyouInfo.data?.match_funding_m2
                        ? t('match_funding_description', {
                            area: `<strong>${(
                              thankyouInfo.data?.area - thankyouInfo.data?.match_funding_m2
                            ).toLocaleString(LocaleLanguage[language?.toUpperCase() || 'DE'])} m²</strong>`,
                            partnerName: `<strong>${
                              thankyouInfo.data?.partner_name || 'Wilderness International'
                            }</strong>`,
                            areaMatchFunding: `<strong>${thankyouInfo.data?.match_funding_m2?.toLocaleString(
                              LocaleLanguage[language?.toUpperCase() || 'DE'],
                            )} m²</strong>`,
                            totalArea: `<strong>${thankyouInfo.data?.area?.toLocaleString(
                              LocaleLanguage[language?.toUpperCase() || 'DE'],
                            )} m²</strong>`,
                          })
                        : t('description'),
                    }}
                  ></h2>
                  <div className="content">
                    <div>{t('thankyouDes')}</div>
                    <div className="message-thankyou">
                      {t('messageThankyou')}{' '}
                      <a href={THANKYOU_LINK[language?.toUpperCase() || 'DE']} target="_blank" rel="noreferrer">
                        {t('messageThankyouLink')}
                      </a>{' '}
                    </div>
                    <div>{t('thankyouDes1')}</div>
                    <div className="btn-download">
                      <Button
                        type="button"
                        disabled={isDisableDownload || isLoadingDownload}
                        label={t('thankyouBTN')}
                        className="p-button-rounded btn-submit"
                        icon="pi pi-download"
                        iconPos="right"
                        loading={isDisableDownload || isLoadingDownload}
                        onClick={() => onDownloadCertificate()}
                      />
                      {isDisableDownload && <p> {t('warningDownload')}. </p>}
                    </div>
                  </div>
                </div>
                <div className="survey-iframe">
                  <iframe
                    className="survey-iframe-content"
                    id={`JotFormIFrame-${thankyouInfo?.data?.settings?.survey_settings?.jotFormId}`}
                    title="Donor survey"
                    allow="geolocation; microphone; camera; fullscreen"
                    src={`https://form.jotform.com/${thankyouInfo?.data?.settings?.survey_settings?.jotFormId}?donation_id=${
                      thankyouInfo.data?.donation_serial || ''
                    }&language=${language || 'de'}&campaign_name=${
                      thankyouInfo.data?.campaign?.name?.[language] || ''
                    }&region_name=${thankyouInfo.data?.region?.name?.[language] || ''}`}
                    style={{ minWidth: '100%', maxWidth: '100%', height: `${heightSurvey}px`, border: 'none' }}
                    scrolling="no"
                  ></iframe>
                  <script src="https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js"></script>
                  <script>
                    window.jotformEmbedHandler("iframe[id='JotFormIFrame-{thankyouInfo?.data?.settings?.survey_settings?.jotFormId}']",
                    "https://form.jotform.com/")
                  </script>
                </div>
                <div className="video-content">
                  <div className="video">
                    <iframe width="100%" src={thankyouInfo.videoUrl}></iframe>
                  </div>
                  {!thankyouInfo.additionalMessage ? (
                    ''
                  ) : (
                    <div className="video-des">
                      <p>{ReactHtmlParser(thankyouInfo.additionalMessage)}</p>
                    </div>
                  )}
                  <div className="video-des">
                    <h1>
                      {thankyouInfo?.data?.settings?.thankyou_settings?.receipt_headline?.[
                        language?.toLowerCase() || 'de'
                      ] || t('descriptionVideo')}
                    </h1>
                    <p>
                      {thankyouInfo?.data?.settings?.thankyou_settings?.receipt_message?.[
                        language?.toLowerCase() || 'de'
                      ] || t('contentDescription')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 content-right">
            <div className="thank-content-right">
              <h2>{t('dearTextRight')}</h2>
              <div className="content-image-right">
                <p className="content-des">
                  <span>{t('contentRight')}</span>
                  <span>
                    {' '}
                    {parseFloat(thankyouInfo?.data?.area?.toString() || '0')?.toLocaleString(
                      LocaleLanguage[language?.toUpperCase() || 'DE'],
                    )}{' '}
                    m²
                  </span>
                  <span> {t('contentRight1')}</span>
                  <span> {thankyouInfo?.data?.region?.name[language?.toLowerCase() || 'de']} </span>
                  <span>{t('contentRight2')}</span>
                </p>
              </div>
              <div className="format-content">
                <label>{t('labelFormat')}</label>
                <Dropdown value={format} options={FormatList} onChange={onFormatChange} optionLabel="name" />
              </div>
              <div className="format-content">
                <label>{t('labelBackground')}</label>
                <Dropdown
                  value={background}
                  options={BackgroundList}
                  onChange={onBackgroundChange}
                  optionLabel="name"
                />
              </div>
              <div className="image-content">
                <div id="image-with-text">
                  <div className={`img-text ${background.code}-${format.code} image_type_${format.code}`}>
                    <div className="content-text-image">
                      <p>
                        <span>{t('contentRight')}</span>
                      </p>
                      <p>
                        <span>
                          <span>
                            {' '}
                            {parseFloat(thankyouInfo?.data?.area?.toString() || '0')?.toLocaleString(
                              LocaleLanguage[language?.toUpperCase() || 'DE'],
                            )}{' '}
                            m²
                          </span>{' '}
                          {t('contentRight1')}
                        </span>
                        <span> {thankyouInfo?.data?.region?.name[language?.toLowerCase() || 'de']} </span>
                        <span>{t('contentRight2')}</span>
                      </p>
                      <div className="logo-container">
                        <div className="logo-wilderness">
                          <img src={logo_white} alt="wilderness-international-logo" loading="lazy" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="icon-image">
                  {isLoading ? (
                    <Button loading={true} />
                  ) : (
                    <Button
                      icon="pi pi-download"
                      className="p-button-rounded"
                      type="button"
                      aria-label="Filter"
                      onClick={() => dowloadImage()}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast ref={toast}></Toast>
    </div>
  );
};

export default withTranslation('blog')(ThankyouComponent);
